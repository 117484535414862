const Autodesk = window.Autodesk;

export default class BIMAcademyEventHandler extends Autodesk.Viewing.Extension {
  constructor(viewer, options = {}) {
    super(viewer, options);

    this._viewer = viewer;
    this.viewer = viewer;

    this._options = options;
    this.options = options;

    // An object with {Autodesk.Viewing.EVENT_NAME: eventHandlerCallback}
    this._unloadHandler = options.unloadHandler;
    this._eventHandlers = options.eventHandlers || {};
    this._boundEventHandlers = {};
  }

  static get ExtensionId() {
    return "BIMAcademy.Event.Handler";
  }

  load() {
    if (this._unloadHandler === null) {
      console.error(
        "BIMAcademy.Event.Handler requires a `unloadHandler` option"
      );
      return false;
    } else {
      this.bindEventListeners();
    }
    return true;
  }

  unload() {
    this.unloadHandler();
    this.unbindEventListeners();
    return true;
  }

  getEventHandlers() {
    return this._eventHandlers;
  }

  getBoundEventHandlers() {
    return this._boundEventHandlers;
  }

  deleteBoundEventHandler(eventName) {
    delete this._boundEventHandlers[eventName];
  }

  bindEventHandler(eventName, handler) {
    const boundHandler = handler.bind(this);
    this._boundEventHandlers[eventName] = boundHandler;
    return boundHandler;
  }

  bindEventListeners() {
    const handlers = this.getEventHandlers();
    for (const [eventName, handler] of Object.entries(handlers)) {
      const boundHandler = this.bindEventHandler(eventName, handler);
      this.viewer.addEventListener(eventName, boundHandler);
    }
  }

  unbindEventListeners() {
    const boundHandlers = this.getBoundEventHandlers();

    for (const [eventName, boundHandler] of Object.entries(boundHandlers)) {
      this.viewer.removeEventListener(eventName, boundHandler);
      this.deleteBoundEventHandler(eventName);
    }
  }

  unloadHandler() {
    this._unloadHandler();
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  "BIMAcademy.Event.Handler",
  BIMAcademyEventHandler
);